

















































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SettingsGridPage extends Vue {
  // todo this needs to be wired up
  //isLoaded = true;
}
